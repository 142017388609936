import IconFileWhiteOnYellowBg from '@/assets/icons/IconFileWhiteOnYellowBg.svg?react';
import { useTranslation } from 'react-i18next';
import { Button } from '../ButtonVariants';

export const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-[60vh] w-full items-center justify-center">
      <div className="text-center">
        <div className="flex justify-center pb-5">
          <IconFileWhiteOnYellowBg />
        </div>

        <div className="block pb-5">
          <span className="block pb-5 text-[26px] font-semibold text-black-magic">{t('error.error')}</span>
          <span className="block text-[17px] font-normal text-light-black-magic">
            {t('label.problemWithLoadingPage')}
          </span>
        </div>

        <div className="flex justify-center">
          <Button variant="primary" size="m" className="min-w-[253px] px-6" onClick={() => window.location.reload()}>
            {t('button.label.reloadThePage')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
