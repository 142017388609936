import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import '@mantine/core/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import './index.css';

import { ApolloProvider } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { LoadingOverlay, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import capitalize from 'lodash/capitalize';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Toaster } from 'sonner';

import { initI18N, Language } from '@/components/I18N';
import { client } from '@/config/ApolloClientProvider.tsx';
import { initHotjar } from '@/config/hotjar';
import { initPosthog } from '@/config/posthog';
import initSentry from '@/config/sentry/initSentry';
import { mantineTheme, resolver } from '@/theme/mantine';

import routes from './routes/router';

initSentry();
initHotjar();
initPosthog();

if (import.meta.env.MODE === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);

// TODO: looks like we might need a separate file for this kind of locale updates
dayjs.updateLocale(Language.PT, {
  weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_').map(capitalize),
});

initI18N();
// This is a default one, just like in the initI18N(); (default probably should be shared)
dayjs.locale(Language.PT);

window.addEventListener('vite:preloadError', (e) => {
  // TODO: make a request to check new version and show a notification
  e.preventDefault();
  window.location.reload();
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <ApolloProvider client={client}>
        <MantineProvider theme={mantineTheme} cssVariablesResolver={resolver}>
          <ModalsProvider>
            <Notifications autoClose={8000} />
            <RouterProvider router={routes} fallbackElement={<LoadingOverlay visible />} />
            <Toaster />
          </ModalsProvider>
        </MantineProvider>
      </ApolloProvider>
    </PostHogProvider>
  </React.StrictMode>
);
