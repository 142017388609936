import { Outlet } from 'react-router-dom';

import PostHogPageView from '@/config/posthog/pageview';

export const RootRoute = () => {
  return (
    <>
      <PostHogPageView />
      <Outlet />
    </>
  );
};
