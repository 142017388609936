/* eslint-disable react-refresh/only-export-components */
import { createBrowserRouter } from 'react-router-dom';

import { ErrorPage } from '@/components/ErrorPage/ErrorPage';

import { RootRoute } from './root';

export default createBrowserRouter([
  {
    path: '/',
    element: <RootRoute />,
    children: [
      {
        path: ':country?/auth',
        lazy: () => import('@/layouts/AuthLayout'),
        children: [
          { index: true, lazy: () => import('@/routes/auth') },
          { path: 'magic-code', lazy: () => import('@/routes/auth/magic-code') },
          { path: 'callback', lazy: () => import('@/routes/auth/callback') },
        ],
      },
      {
        path: ':country?/auth/redirect',
        lazy: () => import('@/routes/auth/success-redirect'),
      },
      {
        path: ':country?/get-started',
        lazy: () => import('@/layouts/OnboardingLayout'),
        children: [
          { index: true, lazy: () => import('@/routes/get-started') },
          { path: 'confirm', lazy: () => import('@/routes/get-started/confirm') },
          { path: 'waitlist', lazy: () => import('@/routes/get-started/waitlist') },
        ],
      },
      {
        path: '/',
        lazy: () => import('@/layouts/AppLayout'),
        children: [
          // { path: '/dashboard', lazy: () => import('@/routes/dashboard') },
          // { path: '/wallet', lazy: () => import('@/routes/wallet') },
          { path: '/invoices', lazy: () => import('@/routes/invoices/list') },
          { path: '/invoices/:invoiceId', lazy: () => import('@/routes/invoices/item') },
          { path: '/invoices/:invoiceId/edit', lazy: () => import('@/routes/invoices/edit') },
          { path: '/invoices/create', lazy: () => import('@/routes/invoices/create') },
          { path: '/invoices/:invoiceId/preview', lazy: () => import('@/routes/invoices/preview-pdf') },
          { path: '/customers', lazy: () => import('@/routes/customers/list') },
          { path: '/customers/create', lazy: () => import('@/routes/customers/create') },
          { path: '/items', lazy: () => import('@/routes/items/list') },
          { path: '/items/create', lazy: () => import('@/routes/items/create') },
          // { path: '/integrations', lazy: () => import('@/routes/integrations') },
          {
            path: '/settings',
            lazy: () => import('@/routes/settings'),
            children: [
              // { index: true, lazy: () => import('@/components/Settings/GeneralSettings') },
              { index: true, lazy: () => import('@/components/Settings/Business') },
              { path: 'at', lazy: () => import('@/components/Settings/AtCommunication') },
              { path: 'invoicing', lazy: () => import('@/components/Settings/Invoicing') },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
]);
