import {
  Alert,
  Button,
  CSSVariablesResolver,
  Card,
  Input,
  InputBase,
  MantineColorsTuple,
  Modal,
  Switch,
  TextInput,
  createTheme,
  rem,
} from '@mantine/core';
import buttonExtendedClasses from './buttonExtendedClasses.module.css';
import inputExtendedClasses from './inputExtendedClasses.module.css';
import switchExtendedClasses from './switchExtendedClasses.module.css';

const fizPurple: MantineColorsTuple = [
  '#f5f1fd',
  '#e6e1ee',
  '#cabfd9',
  '#ae9dc4',
  '#957fb3',
  '#866ca8',
  '#7e63a4',
  '#6d538f',
  '#614881',
  '#543d73',
];

const fizYellow: MantineColorsTuple = [
  '#fff9e1',
  '#fff0cc',
  '#ffe09b',
  '#ffce64',
  '#ffc038',
  '#ffb61c',
  '#ffb209',
  '#e39c00',
  '#ca8a00',
  '#af7600',
];

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--table-highlight-on-hover-color': '#fcfcfc',

    // applies to TextInput component (56px per latest figma), use: <TextInput size="fiz-lg" />
    '--input-height-fiz-lg': theme.other.fizLgTextInputHeight,
    '--mantine-font-size-fiz-lg': theme.other.fizLgTextInputFontSize,

    // Button size="fiz-lg"
    '--button-height-fiz-lg': theme.other.fizLgButtonHeight,
  },
  light: {
    '--table-highlight-on-hover-color': '#fcfcfc',
  },
  dark: {
    '--table-highlight-on-hover-color': '#fcfcfc',
  },
});

export const mantineTheme = createTheme({
  colors: {
    fizPurple,
    fizYellow,
  },
  primaryColor: 'fizYellow',
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
  },
  components: {
    Button: Button.extend({
      classNames: buttonExtendedClasses,
      defaultProps: {
        radius: 'lg',
      },
    }),
    Input: Input.extend({
      classNames: inputExtendedClasses,
      defaultProps: {
        radius: 'lg',
      },
    }),
    InputBase: InputBase.extend({
      classNames: inputExtendedClasses,
      defaultProps: {
        radius: 'lg',
      },
    }),
    TextInput: TextInput.extend({
      classNames: inputExtendedClasses,
      defaultProps: {
        radius: 'lg',
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        radius: 'lg',
      },
    }),
    Alert: Alert.extend({
      defaultProps: {
        radius: 'lg',
      },
    }),
    Card: Card.extend({
      defaultProps: {
        radius: 'lg',
      },
    }),
    Switch: Switch.extend({
      classNames: switchExtendedClasses,
      vars: () => ({
        root: {
          '--switch-thumb-size': rem(27),
        },
      }),
    }),
  },
  other: {
    fizLgTextInputHeight: rem(56),
    fizLgTextInputFontSize: rem(17),
    fizLgButtonHeight: rem(56),
  },
});
